import {InjectionToken} from '@angular/core';

export type Config = {
  production: boolean;
  envName: string;
  merchantUIOrigin: string;
  adminUIOrigin: string;
  billingUrl: string;
  platformBillingApiUrl: string;
  consumersApiUrl: string;
  enterpriseApiUrl: string;
  affiliateApiUrl: string;
  hapiUrl: string;
  offersUrl: string;
  paymentsUrl: string;
  sherlockUrl: string;
  stapiUrl: string;
  uidUrl: string;
  walletUrl: string;
  cVaultUrl: string;
  decafUrl: string;
  concrConfigUrl: string;
  wcWrapperUrl: string;
  checkoutUrl: string;
  loansEnabled: boolean,
  auth: {
    clientID: string;
    domain: string;
    audience: string;
    redirect: string;
    scope: string;
  }
};

export const APP_CONFIG: InjectionToken<Config> = new InjectionToken<Config>(
  'Application Config'
);
