import {Component, OnInit, ViewChild} from '@angular/core';
import {WalletService} from '../../../services/wallet.service';
import {AppMetadataItem} from '../../../generated/wallet/model/appMetadataItem';
import {ErrorDisplayService} from '../../../services/error-display.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppMetadataRequest} from '../../../generated/wallet/model/appMetadataRequest';
import {Table} from 'primeng/table';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {FilterField} from '../../../domain/common/search/filter-field';
import {AuthRoles} from '../../../domain/auth/auth-roles';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-page-firmware',
  templateUrl: './page-app-metadata.component.html',
  styleUrls: ['./page-app-metadata.component.css']
})
export class PageAppMetadataComponent implements OnInit {
  metadata: AppMetadataItem[] = [];
  totalRecords: number;
  loading = true;
  @ViewChild('dt') datatable: Table;
  cols = [
    { field: 'id', header: 'Id' },
    { field: 'parameter', header: 'Parameter' },
    { field: 'path', header: 'Path' },
    { field: 'title', header: 'Title' },
    { field: 'type', header: 'Type' },
    { field: 'url', header: 'Url' },
    { field: 'content', header: 'Content' },
    { field: 'actions', header: 'Actions' }
  ];
  formVisible = false;
  submitForm: FormGroup;
  searchForm: FormGroup;
  hasPerms = false;
  showContent = false;
  selectedContent = null;

  typeOptions = Object.values(AppMetadataItem.TypeEnum)
    .filter(type => typeof type === 'string');

  constructor(private walletService: WalletService,
              private errorDisplayService: ErrorDisplayService,
              private confirmationService: ConfirmationService,
              private authService: AuthService,
              private fb: FormBuilder) { }

  ngOnInit() {
    this.initForms();

    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT])
      .subscribe(result => this.hasPerms = result);
  }

  getData(filters: FilterField[] = []) {
    this.loading = true;
    this.walletService.getAppMetadata(filters).subscribe(result => {
      this.metadata = result;
      this.totalRecords = result.length;
      this.loading = false;
    }, err => {
      this.loading = false;
      this.errorDisplayService.displayErrorResponse('Get Metadata', err);
    });
  }

  deleteMetadataItem(metadataItem: AppMetadataItem) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this metadata?',
      header: 'Delete Confirmation',
      icon: 'fa fa-trash',
      accept: () => {
        this.loading = true;
        this.walletService.deleteAppMetadataItem(metadataItem.id).subscribe(result => {
          const index = this.metadata.findIndex(f => f.id === metadataItem.id);
          this.metadata.splice(index, 1);
          this.totalRecords = this.metadata.length;
          this.loading = false;
        }, err => {
          this.loading = false;
          this.errorDisplayService.displayErrorResponse('Delete Metadata', err);
        });
      },
      reject: () => {}
    });
  }

  initForms() {
    this.submitForm = this.fb.group({
      content: ['', [Validators.required]],
      parameter: ['', [Validators.required]],
      path: ['', [Validators.required]],
      title: ['', [Validators.required]],
      type: ['', [Validators.required]],
      url: ['', [Validators.required]],
    });

    this.searchForm = this.fb.group({
      type: [''],
      path: ['']
    })
  }

  clearForm() {
    this.submitForm.reset();
  }

  handleSubmitFormSubmit() {
    this.loading = true;
    const req: AppMetadataRequest = Object.assign({}, this.submitForm.value);
    this.walletService.postAppMetadata(req).subscribe(result => {
      this.getData(this.getFilterFields());
    }, err => {
      this.loading = false;
      this.errorDisplayService.displayErrorResponse('Submit Metadata', err);
    });
  }

  getFilterFields() {
    const filters: FilterField[] = [];
    if (this.searchForm.controls.type.value) {
      filters.push({parameter: 'type', value: this.searchForm.controls.type.value});
    }
    if (this.searchForm.controls.path.value) {
      filters.push({parameter: 'path', value: this.searchForm.controls.path.value});
    }
    return filters;
  }

  loadDataLazy(event: LazyLoadEvent) {
    this.getData(this.getFilterFields());
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadDataLazy(this.datatable.createLazyLoadMetadata());
    this.searchForm.markAsPristine();
  }

  resetForm() {
   this.searchForm.reset();
  }
}
