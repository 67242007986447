import './polyfills.ts';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableProdMode} from '@angular/core';
import {AppModule} from './app';
import {APP_CONFIG, Config} from '../config/config';

fetch('./config/config.json')
  .then((resp) => resp.json())
  .then((config: Config) => {
    if (config.production) {
      enableProdMode();
    }
    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
