import {Inject, Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Person} from '../domain/consumers/person';
import {Consumer} from '../domain/consumers/consumer';
import {FilterField} from '../domain/common/search/filter-field';
import {PageRequest, PageResponse} from '../domain/common/paging';
import {ConvertedLead} from '../domain/consumers/converted-lead';
import {Lead} from '../domain/consumers/lead';
import {LeadStats} from '../domain/consumers/lead-stats';
import {APP_CONFIG, Config} from '../../../config/config';

@Injectable()
export class ConsumersApiService extends BaseService {

  constructor(@Inject(APP_CONFIG) readonly config: Config,
              private http: HttpClient) {
 super();
}

  getConsumer(uuid: string): Observable<Consumer> {
    return this.http.get<Consumer>(`${this.config.consumersApiUrl}/consumers/${uuid}`)
      .pipe(
        catchError(this.handleError)
      )
  }

  getPersonByEmail(email: string): Observable<Person[]> {
    return this.http.get<Person>(`${this.config.consumersApiUrl}/persons?email=${encodeURIComponent(email)}`)
      .pipe(
        catchError(this.handleError)
      )
  }

  getPersonByPhone(phone: string): Observable<Person[]> {
    return this.http.get<Person>(`${this.config.consumersApiUrl}/persons?phoneNumber=${encodeURIComponent(phone)}`)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateConsumerName(user: string, givenName: string, familyName: string) {
    return this.http.patch<Consumer>(`${this.config.consumersApiUrl}/consumers/${user}`,
      {givenName, familyName})
      .pipe(
        catchError(this.handleError)
      )
  }

  createUser(form: any): Observable<Consumer> {
    const url = this.config.consumersApiUrl + '/user/admin';
    const body = {
      userUuid: form.userUuid.length > 0 ? form.userUuid : null,
      firstName: form.firstName.length > 0 ? form.firstName : null,
      lastName: form.lastName.length > 0 ? form.lastName : null,
      device: {
        deviceId: form.deviceId.length > 0 ? form.deviceId : null,
        gcmId: form.gcmId.length > 0 ? form.gcmId : null,
        osType: form.osType.length > 0 ? form.osType : null,
        createToken: true// TODO add toggle for this to UI
      },
      phone: {
        phone: form.phoneNumber.length > 0 ? form.phoneNumber : null,
        countryCode: form.countryCode.length > 0 ? form.countryCode : null,
        verified: true
      },
      email: {
        email: form.email.length > 0 ? form.email : null
      }
    };
    return this.http.post<Consumer>(url, body)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getLeads(pageRequest: PageRequest, filters: FilterField[]): Observable<PageResponse<Lead>> {
    let searchParams  = new HttpParams()
      .append('page', `${pageRequest.page + 1}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      searchParams = searchParams.append(filter.parameter, filter.value);
    });

    return this.http.get<any>(this.config.consumersApiUrl + '/leads', {params: searchParams, observe: 'response'})
      .pipe(
        map(response => {
          const leads = response.body?.map(lead => {
            lead.createdAt = lead.createdAt * 1000;
            return lead;
          });

          const pageResponse = new PageResponse<Lead>();
          pageResponse.content = leads;
          pageResponse.totalElements = Number(response.headers.get('x-total-count'));

          return pageResponse;
        })
      );
  }

  getLeadsConversions(pageRequest: PageRequest, filters: FilterField[]): Observable<PageResponse<ConvertedLead>> {
    let searchParams  = new HttpParams()
      .append('page', `${pageRequest.page + 1}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      searchParams = searchParams.append(filter.parameter, filter.value);
    });

    return this.http.get<any>(this.config.consumersApiUrl + '/leads/conversion', {params: searchParams, observe: 'response'})
      .pipe(
        map(response => {
          const convertedLeads = response.body?.map(convertedLead => {
            convertedLead.convertedAt = convertedLead.convertedAt * 1000;
            return convertedLead;
          });

          const pageResponse = new PageResponse<ConvertedLead>();
          pageResponse.content = convertedLeads;
          pageResponse.totalElements = Number(response.headers.get('x-total-count'));

          return pageResponse;
        })
      );
  }

  getLeadsStats(filters: FilterField[]): Observable<LeadStats> {
    let searchParams  = new HttpParams();

    filters.forEach(filter => {
      searchParams = searchParams.append(filter.parameter, filter.value);
    });

    return this.http.get<LeadStats>(this.config.consumersApiUrl + '/leads/stats', {params: searchParams})
      .pipe(
        catchError(this.handleError)
      );
  }
}
