import {Inject, Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {BuildInfo} from 'app/domain/common/buildinfo';
import build from '../../build';
import {APP_CONFIG, Config} from '../../../config/config';

@Injectable()
export class BuildInfoService extends BaseService {
  buildInfo: BuildInfo;

  constructor(@Inject(APP_CONFIG) readonly config: Config) {
    super();
    this.buildInfo = {
      version: build.version,
      revision: build.git.fullHash,
      built: new Date(build.timestamp).getTime(),
      project: `backoffice-${this.config.envName}`
    } as BuildInfo
  }

  getBuildInfo() {
    return this.buildInfo;
  }
}
