import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Table} from 'primeng/table';
import {PageRequest} from '../../domain/common/paging';
import {FilterField} from '../../domain/common/search/filter-field';
import {LazyLoadEvent} from 'primeng/api';
import {Panel} from 'primeng/panel';
import {Activity} from '../../generated/offers/model/activity';
import {ActivityDetails} from '../../generated/offers/model/activityDetails';
import {OffersService} from '../../services/offers-api.service';
import {User} from '../../generated/offers/model/user';

declare const google: any;

@Component({
  selector: 'app-activities-table',
  templateUrl: './activities-table.component.html',
  styleUrls: ['./activities-table.component.css']
})
export class ActivitiesTableComponent implements OnInit {

  @Input()
  consumerId: string;

  loading = false;
  form: FormGroup;

  types = Object.values(ActivityDetails.RewardActivityTypeEnum).filter(type => typeof type === 'string');
  totalRecords: number;
  activities: Activity[];
  @ViewChild('dt') datatable: Table;
  cols: any[];

  mapOptions: any;
  overlays: any[];
  selectedActivity: Activity;
  selectedUser: string;
  public activityType = ActivityDetails.RewardActivityTypeEnum;

  constructor(private offersService: OffersService,
              private fb: FormBuilder) {}

  ngOnInit() {
    this.initializeForm();

    this.cols = [
      { field: 'created', header: 'Created', sort: true },
      { field: 'offersUser', header: 'User' },
      { field: 'title', header: 'Title' },
      { field: 'activityType', header: 'Type', sort: true },
      { field: 'rewardActivityType', header: 'Reward Type' },
      { field: 'amount', header: 'Amount' },
      { field: 'detail', header: 'Detail' }
    ];
  }

  initializeForm() {
    this.form = this.fb.group({
      startDateTime: [null],
      endDateTime: [null],
      userUuid: [''],
      types: [[]]
    })
  }

  clearForm() {
    this.form.reset()
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadActivitiesLazy(this.datatable.createLazyLoadMetadata());
    this.form.markAsPristine();
  }

  getActivities(pageRequest: PageRequest, filterFields: FilterField[]) {
    this.loading = true;
    this.offersService.getActivities(pageRequest, filterFields).subscribe(data => {
      this.loading = false;
      this.activities = data.content;
      this.totalRecords = data.totalElements;
    }, error => {
      this.loading = false;
    })
  }

  loadActivitiesLazy(event: LazyLoadEvent) {
    const filters: FilterField[] = this.getFilterFields();

    filters.push({
      parameter: 'sort',
      value: event.sortField + ',' + (event.sortOrder === 1 ? 'asc' : 'desc')
    });

    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};
    this.getActivities(pageRequest, filters);
  }

  getFilterFields(): FilterField[] {
    const filterFields: FilterField[] = [];
    if (this.form.controls.startDateTime.value) {
      filterFields.push({parameter: 'startDate', value: this.form.controls.startDateTime.value.getTime()});
    }
    if (this.form.controls.endDateTime.value) {
      filterFields.push({parameter: 'endDate', value: this.form.controls.endDateTime.value.getTime()});
    }
    if (this.consumerId) {
      filterFields.push({parameter: 'userUuid', value: this.consumerId});
    } else if (this.form.controls.userUuid.value) {
      filterFields.push({parameter: 'userUuid', value: this.form.controls.userUuid.value});
    }
    if (this.form.controls.types.value) {
      const typesSelected: string[] = this.form.controls.types.value;
      if (typesSelected.length > 0) {
        filterFields.push({parameter: 'activityTypes', value: typesSelected.join(',').toLowerCase()});
      }
    }
    return filterFields;
  }

  selectActivity(activity) {
    if (activity.latitude) {
      this.mapOptions = {
        center: {lat: activity.latitude, lng: activity.longitude},
        zoom: 16
      };
      this.overlays = activity.latitude ? [new google.maps.Marker({position: {lat: activity.latitude, lng: activity.longitude}, title: activity.verifiedLocation.placeName})] : [];
    }
    this.selectedActivity = activity
  }

  showUserPreview(event, user: User, panel: Panel) {
    this.selectedUser = user.uuid;
    panel.toggle(event);
  }

  fontawesomeIconClass(activity: Activity): string {
    switch ((activity as any).activityType) {
      case ActivityType.SPEND:
        return 'fa-shopping-cart';
      case ActivityType.REWARD:
        return 'fa-shopping-cart';
      case ActivityType.RECEIVE:
        return 'fa-cc-stripe';
      case ActivityType.UNLOAD:
        return 'fa-minus-square';
      case ActivityType.SHARE_OFFER:
        return 'fa-share-square';
      case ActivityType.SHARE_GIFT:
        return 'fa-share-square';
      case ActivityType.LOAD:
        return 'fa-plus-square';
      case ActivityType.PAYMENT:
        return 'fa-money-bill';
      case ActivityType.LOAN_PAYMENT:
        return 'fa-money-bill';
      case ActivityType.BALANCE_ADJUSTMENT:
        return 'fa-edit';
    }
  }

  iconColor(activity: Activity): string {
    switch ((activity as any).activityType) {
      case ActivityType.SPEND:
        return 'green';
      case ActivityType.REWARD:
        return 'green';
      case ActivityType.UNLOAD:
        return 'green';
      case ActivityType.RECEIVE:
        return 'blue';
      case ActivityType.SHARE_OFFER:
        return 'blue';
      case ActivityType.SHARE_GIFT:
        return 'orange';
      case ActivityType.LOAD:
        return 'red';
      case ActivityType.PAYMENT:
        return 'red';
      case ActivityType.LOAN_PAYMENT:
        return 'red';
      case ActivityType.BALANCE_ADJUSTMENT:
      default:
        return 'black';
    }
  }
}

export enum ActivityType {
  RECEIVE = 'receive',
  SPEND = 'spend',
  LOAD = 'load',
  LOAN_PAYMENT = 'loan_payment',
  SHARE_OFFER = 'share_offer',
  UNLOAD = 'unload',
  SHARE_GIFT = 'share_gift',
  PAYMENT = 'payment',
  BALANCE_ADJUSTMENT = 'balance_adjustment',
  REWARD = 'reward',
  RECEIVE_GIFT = 'RECEIVE_GIFT'
}
