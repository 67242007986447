import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {User} from '../../generated/offers/model/user';
import {OffersService} from '../../services/offers-api.service';

@Component({
  selector: 'app-merchant-detail',
  templateUrl: './merchant-detail.component.html',
  styleUrls: ['./merchant-detail.component.css']
})


export class MerchantDetailComponent implements OnInit {

  merchant: User;
  pendingRequest = true;

  constructor(private offersService: OffersService,
              private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      const merchantUuid = params['uuid'];
      this.offersService.getMerchant(merchantUuid).subscribe(merchant => {
        this.merchant = merchant;
        this.pendingRequest = false;
      })
    })
  }

  ngOnInit() {
  }
}
