import {Component, Input, OnInit} from '@angular/core';
import {FeedItem} from '../../generated/offers/model/feedItem';

@Component({
  selector: 'app-feed-item',
  templateUrl: './feed-item.component.html',
  styleUrls: ['./feed-item.component.css']
})
export class FeedItemComponent implements OnInit {
  @Input() feedItem: FeedItem;

  constructor() {}

  ngOnInit() {}

  fontawesomeIconClass() {
    switch (this.feedItem.type) {
      case ActivityType.SPEND:
        return 'fa-shopping-cart';
      case ActivityType.RECEIVE:
        return 'fa-cc-stripe';
      case ActivityType.RECEIVE_GIFT:
        return 'fa-gift';
    }
  }

  iconColor() {
    switch (this.feedItem.type) {
      case ActivityType.SPEND:
      case ActivityType.RECEIVE_GIFT:
        return 'green';
      case ActivityType.RECEIVE:
        return 'blue';
      default:
        return 'black';
    }
  }

  isReceive() {
    return this.feedItem.type === ActivityType.RECEIVE;
  }

  isSpend() {
    return this.feedItem.type === ActivityType.SPEND;
  }

  isReceiveGift() {
    return this.feedItem.type === ActivityType.RECEIVE_GIFT;
  }
}

export enum ActivityType {
  RECEIVE = 'RECEIVE',
  SPEND = 'SPEND',
  LOAD = 'LOAD',
  LOAN_PAYMENT = 'LOAN_PAYMENT',
  SHARE_OFFER = 'SHARE_OFFER',
  UNLOAD = 'UNLOAD',
  SHARE_GIFT = 'SHARE_GIFT',
  PAYMENT = 'PAYMENT',
  BALANCE_ADJUSTMENT = 'BALANCE_ADJUSTMENT',
  REWARD = 'REWARD',
  RECEIVE_GIFT = 'RECEIVE_GIFT'
}

