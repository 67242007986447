import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {OffersService} from '../../../services/offers-api.service';
import {LazyLoadEvent} from 'primeng/api';
import {PageRequest} from '../../../domain/common/paging';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {AuthRoles} from '../../../domain/auth/auth-roles';
import {ErrorDisplayService} from '../../../services/error-display.service';
import {ClosedLoopProgram} from '../../../generated/offers/model/closedLoopProgram';

@Component({
  selector: 'app-page-reward-programs',
  templateUrl: './page-closed-loop-programs.component.html',
  styleUrls: ['./page-closed-loop-programs.component.css']
})
export class PageClosedLoopProgramsComponent implements OnInit {
  loading = true;
  newDisable = true;
  @ViewChild('closedLoopDT') closedLoopDataTable: Table;
  closedLoopPrograms: ClosedLoopProgram[] = [];
  closedLoopProgramsCols = [
    { field: 'status', header: 'Status' },
    { field: 'created', header: 'Created' },
    { field: 'uuid', header: 'Uuid' },
    { field: 'title', header: 'Title' },
    { field: 'description', header: 'Description' },
    { field: 'action', header: '' },
  ];
  closedLoopProgramsTotalRecords: number;
  selectedClosedLoopProgram: ClosedLoopProgram;
  showSelectedClosedLoopProgram = false;

  constructor(private offersService: OffersService,
              private errorDisplayService: ErrorDisplayService,
              private router: Router,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT]).subscribe(result => this.newDisable = !result);
  }

  getClosedLoopPrograms(pageRequest: PageRequest) {
    this.loading = true;
    this.offersService.getClosedLoopPrograms(null, pageRequest).subscribe(result => {
      this.closedLoopPrograms = result.content;
      this.closedLoopProgramsTotalRecords = result.totalElements
      this.loading = false;
    }, err => {
      this.errorDisplayService.displayErrorResponse('Get Closed Loop Programs', err);
      this.loading = false;
    });
  }

  loadClosedLoopProgramsLazy(event: LazyLoadEvent) {
    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};

    this.getClosedLoopPrograms(pageRequest);
  }

  newRewardProgram() {
    this.router.navigateByUrl('offers/rewardprogram');
  }
}
