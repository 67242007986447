import {Component, Input, Output, OnInit, ViewChild, EventEmitter} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Table} from 'primeng/table';
import {OffersService} from '../../services/offers-api.service';
import {LazyLoadEvent} from 'primeng/api';
import {PageRequest} from '../../domain/common/paging';
import {FilterField} from '../../domain/common/search/filter-field';
import {ErrorDisplayService} from '../../services/error-display.service';
import {RewardCampaign} from '../../generated/offers/model/rewardCampaign';
import {RewardProgramLocation} from '../../generated/offers/model/rewardProgramLocation';
import {RewardActivity} from '../../generated/offers/model/rewardActivity';
import {RewardActivityResponse} from '../../generated/offers/model/rewardActivityResponse';
import {BrandProgramMid} from '../../generated/offers/model/brandProgramMid';
import {RewardActivityPerformance} from '../../domain/reward-activity-performance';

@Component({
  selector: 'app-reward-program-log-table',
  templateUrl: './reward-program-log-table.component.html',
  styleUrls: ['./reward-program-log-table.component.css']
})
export class RewardProgramLogTableComponent implements OnInit {

  @ViewChild('dt') datatable: Table;

  @Input() mids: BrandProgramMid[] = [];
  @Input() rewardCampaigns: RewardCampaign[] = [];
  @Input() brandId: string;
  @Input() rewardProgramCreation: number;
  @Input() locations: RewardProgramLocation[];

  logs: RewardActivityResponse[] = [];
  performance: RewardActivityPerformance = {
    earnedRewardsTotal: 0,
    earnedRewardsCount: 0,
    spentRewardsTotal: 0,
    spentRewardsCount: 0,
    cancelledRewardsTotal: 0,
    cancelledRewardsCount: 0,
    expiredRewardsTotal: 0,
    expiredRewardsCount: 0,
    reversedRewardsTotal: 0,
    reversedRewardsCount: 0
  };
  loading = true;
  cols: any[];
  form: FormGroup;
  formVisible = false;
  dateRanges: string[] = [
    'this month',
    'last month',
    'last 3 months',
    'last 6 months',
    'last 9 months',
    'last year',
    'all time'
  ];
  dateRangeSelected: string = this.dateRanges[0];
  rewardActivityTypeList = Object.keys(RewardActivity.RewardActivityTypeEnum).map(val => ({
    label: val,
    value: RewardActivity.RewardActivityTypeEnum[val]
  }));
  totalRecords: number;
  terminalIds = [];

  filterUsing = 'LOCATION';

  @Output() exportFilterData = new EventEmitter<{
    exportFilters: FilterField[];
    exportTableSize: number;
    exportTablePage: number;
  }>();

  constructor(private offersService: OffersService,
              private errorDisplayService: ErrorDisplayService,
              private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initializeForm();
    this.cols = [
      { field: 'amount', header: 'Amount' },
      { field: 'userId', header: 'User' },
      { field: 'rewardCampaignId', header: 'Reward Campaign' },
      { field: 'date', header: 'Date (in CST)' },
      { field: 'type', header: 'Type' }
    ];

    this.mids?.forEach(rpm => rpm.mid.verifiedLocations?.forEach(vml => {
      if (vml.terminalId) {
        this.terminalIds.push(vml.terminalId)
      }
    }));
  }

  resetForm() {
    this.form.reset();
    this.dateRangeSelected = this.dateRanges[0];
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadDataLazy(this.datatable.createLazyLoadMetadata());
    this.form.markAsPristine();
  }

  initializeForm() {
    this.form = this.fb.group({
      rewardCampaignsSelected: [''],
      rewardActivityTypesSelected: [''],
      startDate: [''],
      endDate: [''],
      terminalIdsSelected: [''],
    })
  }

  getLogs(pageRequest: PageRequest, filterFields: FilterField[]) {
    this.loading = true;
    this.exportFilterData.emit({
      exportFilters: filterFields,
      exportTableSize: pageRequest.size,
      exportTablePage: pageRequest.page
    });
    this.offersService.getRewardProgramRewardActivity(this.brandId, pageRequest, filterFields).subscribe(result => {
      this.totalRecords = result.totalElements;
      this.logs = result.content;
      this.loading = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Reward Program Activities', error);
      this.loading = false;
    });
  }

  getFilterFields(): FilterField[] {
    const filterFields: FilterField[] = [];
    if (this.form.get('rewardCampaignsSelected').value?.length > 0) {
      filterFields.push({parameter: 'rewardCampaignIds', value: this.form.get('rewardCampaignsSelected').value});
    }
    if (this.form.get('terminalIdsSelected').value?.length > 0) {
      let value = [];
      if (this.filterUsing === 'TERMINAL IDS') {
        value = this.form.controls.terminalIdsSelected.value;
      } else if (this.filterUsing === 'LOCATION') {
        this.form.controls.terminalIdsSelected.value?.forEach(location => {
          location.terminals?.map(terminal => terminal.terminal).forEach(terminalId => {
            if (value.indexOf(terminalId) === -1) {
              value.push(terminalId);
            }
          });
        });
      }
      filterFields.push({parameter: 'midTerminalIds', value});
    }
    if (this.form.get('rewardActivityTypesSelected').value?.length > 0) {
      filterFields.push({parameter: 'rewardActivityTypes', value: this.form.get('rewardActivityTypesSelected').value});
    }
    if (this.dateRangeSelected) {
      const [startDate, endDate] = this.getTimeFromRange(this.dateRangeSelected);
      filterFields.push({parameter: 'startDate', value: startDate.getTime()});
      filterFields.push({parameter: 'endDate', value: endDate.getTime()});
    }
    return filterFields;
  }

  loadDataLazy(event: LazyLoadEvent) {
    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};

    const filterFields = this.getFilterFields();

    this.getLogs(pageRequest, filterFields);
    this.calculateRewardActivityTypeSummary(filterFields);
  }

  getRewardCampaignName(rewardCampaignId: string) {
    return this.rewardCampaigns.find(x => x.uuid === rewardCampaignId)?.title;
  }

  calculateRewardActivityTypeSummary(filterFields: FilterField[]) {
    this.loading = true;
    this.offersService.getRewardProgramActivityTotals(this.brandId, filterFields).subscribe(result => {
      const earns = result.totals?.find(x =>
        x.rewardActivityType === RewardActivity.RewardActivityTypeEnum.Earned
      );
      this.performance.earnedRewardsTotal = earns?.amount ? earns.amount : 0;
      this.performance.earnedRewardsCount = earns?.count ? earns.count : 0;
      const spent= result.totals?.find(x =>
        x.rewardActivityType === RewardActivity.RewardActivityTypeEnum.Burned
      );
      this.performance.spentRewardsTotal = spent?.amount ? spent.amount : 0;
      this.performance.spentRewardsCount = spent?.count ? spent.count : 0;
      const cancels= result.totals?.find(x =>
        x.rewardActivityType === RewardActivity.RewardActivityTypeEnum.Cancelled
      );
      this.performance.cancelledRewardsTotal = cancels?.amount ? cancels.amount : 0;
      this.performance.cancelledRewardsCount = cancels?.count ? cancels.count : 0;
      const expired= result.totals?.find(x =>
        x.rewardActivityType === RewardActivity.RewardActivityTypeEnum.Expired
      );
      this.performance.expiredRewardsTotal = expired?.amount ? expired.amount : 0;
      this.performance.expiredRewardsCount = expired?.count ? expired.count : 0;
      const reversed= result.totals?.find(x =>
        x.rewardActivityType === RewardActivity.RewardActivityTypeEnum.Reversal
      );
      this.performance.reversedRewardsTotal = reversed?.amount ? reversed.amount : 0;
      this.performance.reversedRewardsCount = reversed?.count ? reversed.count : 0;

      this.loading = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Reward Program Performance', error);
      this.loading = false;
    });
  }

  mapRewardActivityType(type: RewardActivity.RewardActivityTypeEnum) {
    const indexOfType = Object.values(RewardActivity.RewardActivityTypeEnum).indexOf(type);
    return Object.keys(RewardActivity.RewardActivityTypeEnum)[indexOfType];
  }

  getTimeFromRange(range: string): Date[] {
    let startDate = new Date();
    startDate.setDate(1);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 59);
    switch (this.dateRangeSelected) {
      case 'this month':
        break;
      case 'last month':
        startDate.setMonth(startDate.getMonth() - 1);
        endDate.setDate(0);
        startDate.setMonth(startDate.getMonth());
        break;
      case 'last 3 months':
        startDate.setMonth(startDate.getMonth() - 2);
        break;
      case 'last 6 months':
        startDate.setMonth(startDate.getMonth() - 5);
        break;
      case 'last 9 months':
        startDate.setMonth(startDate.getMonth() - 8);
        break;
      case 'last year':
        startDate.setMonth(startDate.getMonth() - 11);
        break;
      case 'all time':
        startDate = new Date(this.rewardProgramCreation);
        break;
    }
    //convert to CST from UTC
    startDate.setHours(startDate.getHours() - 6);
    endDate.setHours(endDate.getHours() - 6);
    return [startDate, endDate];
  }

  locationsAsStringList(locations): string {
    if (locations === '') {
      return '';
    }
    return locations?.map(option => option.channel === 'ONLINE' ? option.endpoint : option.placeName).join(', ');
  }
}
