import {Component, Input, Output, OnInit, ViewChild, EventEmitter} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Table} from 'primeng/table';
import {OffersService} from '../../services/offers-api.service';
import {LazyLoadEvent} from 'primeng/api';
import {PageRequest} from '../../domain/common/paging';
import {FilterField} from '../../domain/common/search/filter-field';
import {ErrorDisplayService} from '../../services/error-display.service';
import {QualifyingTransaction} from '../../generated/offers/model/qualifyingTransaction';
import {RewardProgram} from '../../generated/offers/model/rewardProgram';
import {Transaction} from '../../generated/offers/model/transaction';
import {ActivityDetails} from '../../generated/offers/model/activityDetails';

@Component({
  selector: 'app-reward-program-transaction-table',
  templateUrl: './reward-program-transaction-table.component.html',
  styleUrls: ['./reward-program-transaction-table.component.css']
})
export class RewardProgramTransactionTableComponent implements OnInit {
  logs: QualifyingTransaction[] = [];
  loading = true;
  showSelectedDialog = false;
  cols: any[];
  form: FormGroup;
  formVisible = false;
  selectedTransaction: Transaction;

  @Output() exportFilterData = new EventEmitter<{
    exportFilters: FilterField[];
    exportTableSize: number;
    exportTablePage: number;
  }>();

  totalRecords: number;

  txnStatusOptions = Object.values(ActivityDetails.TxnStatusEnum).filter(type => typeof type === 'string');
  rewardCampaignTitles: string[];

  @Input() uuid: string;
  @Input() rewardProgram: RewardProgram;

  @ViewChild('dt') datatable: Table;

  constructor(private offersService: OffersService,
              private fb: FormBuilder,
              private errorDisplayService: ErrorDisplayService) { }

  ngOnInit(): void {
    this.rewardCampaignTitles = this.rewardProgram?.rewardCampaigns?.map(rc => rc.title);

    this.initializeForm();

    this.cols = [
      { field: 'date', header: 'Timestamp (in CST)' },
      { field: 'amount', header: 'Approved Transaction $' },
      { field: 'txnStatus', header: 'Transaction Status' },
      { field: 'userCashSpent', header: 'User Cash Spent' },
      { field: 'spentRewardAmount', header: 'Rewards Burned' },
      { field: 'earnedRewardAmount', header: 'Rewards Earned' },
      { field: 'consumerUuid', header: 'Consumer' },
      { field: 'details', header: 'Details' },
    ];
  }

  resetForm() {
    this.form.reset();
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadDataLazy(this.datatable.createLazyLoadMetadata());
    this.form.markAsPristine();
  }

  initializeForm() {
    this.form = this.fb.group({
      startDate: [''],
      endDate: [''],
      rewardCampaignTitle: [''],
      txnStatus: [''],
      consumerFirstName: [''],
    })
  }

  getLogs(pageRequest: PageRequest, filterFields: FilterField[]) {
    this.loading = true;
    this.exportFilterData.emit({
      exportFilters: filterFields,
      exportTableSize: pageRequest.size,
      exportTablePage: pageRequest.page
    });
    this.offersService.getRewardProgramTransactions(this.uuid, pageRequest, filterFields).subscribe(result => {
      this.totalRecords = result.totalElements;
      this.logs = result.content;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Reward Program Transactions', error);
    }).add(() => {
      this.loading = false;
    });
  }

  getFilterFields(): FilterField[] {
    const filterFields: FilterField[] = [];
    let startDate;
    let endDate;
    if (this.form.get('startDate').value) {
      const temp = this.form.get('startDate').value
      temp.setHours(0,0,0, 0)
      temp.setHours(temp.getHours() - 6);
      startDate = temp.getTime();
      filterFields.push({parameter: 'startDate', value: startDate});
    }
    if (this.form.get('endDate').value) {
      const temp = this.form.get('endDate').value
      temp.setHours(23,59,59, 999)
      temp.setHours(temp.getHours() - 6);
      endDate = temp.getTime();
      filterFields.push({parameter: 'endDate', value: endDate});
    }
    if (this.form.get('rewardCampaignTitle').value) {
      filterFields.push({parameter: 'rewardCampaignTitle', value: this.form.get('rewardCampaignTitle').value});
    }
    if (this.form.get('txnStatus').value) {
      filterFields.push({parameter: 'txnStatus', value: this.form.get('txnStatus').value});
    }
    if (this.form.get('consumerFirstName').value) {
      filterFields.push({parameter: 'consumerFirstName', value: this.form.get('consumerFirstName').value});
    }
    return filterFields;
  }

  loadDataLazy(event: LazyLoadEvent) {
    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};

    const filterFields = this.getFilterFields();

    this.getLogs(pageRequest, filterFields);
  }

  selectTransaction(transaction: QualifyingTransaction) {
    this.offersService.getTransaction(transaction.txnUuid).subscribe(result => {
      this.selectedTransaction = result;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Transaction Details', error);
    });
  }
}
