/**
 * Wallet API
 *
 * Contact: kkozak@ovloop.com, jsanchez@ovloop.com , bpico@ovloop.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AppMetadataItem { 
    /**
     * Data to be displayed
     */
    content?: string;
    /**
     * Paramater Id
     */
    id?: string;
    /**
     * Paramater Name
     */
    parameter?: string;
    /**
     * Path in the app where the data belongs to
     */
    path?: string;
    /**
     * Parameter Title
     */
    title?: string;
    /**
     * Parameter Data Type
     */
    type?: AppMetadataItem.TypeEnum;
    /**
     * URL where the content is located
     */
    url?: string;
}
export namespace AppMetadataItem {
    export type TypeEnum = 'EMAIL' | 'IMAGE' | 'NUMERIC' | 'PAGE' | 'PHONE' | 'TABLE' | 'TEXT';
    export const TypeEnum = {
        Email: 'EMAIL' as TypeEnum,
        Image: 'IMAGE' as TypeEnum,
        Numeric: 'NUMERIC' as TypeEnum,
        Page: 'PAGE' as TypeEnum,
        Phone: 'PHONE' as TypeEnum,
        Table: 'TABLE' as TypeEnum,
        Text: 'TEXT' as TypeEnum
    };
}


