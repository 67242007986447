import {Inject, Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BuildInfo} from '../domain/common/buildinfo';
import {IdentityScoreReport} from '../domain/uid/identity-score-report';
import {Observable} from 'rxjs';
import {PageRequest, PageResponse} from '../domain/common/paging';
import {FilterField} from '../domain/common/search/filter-field';
import {IdentityReport} from '../domain/uid/identity-report';
import {map} from 'rxjs/operators';
import {APP_CONFIG, Config} from '../../../config/config';

@Injectable()
export class UidService extends BaseService  {
  constructor(@Inject(APP_CONFIG) readonly config: Config,
              private http: HttpClient) {
    super();
  }

  getBuildInfo() {
    return this.http.get<BuildInfo>(`${this.config.uidUrl}/build`);
  }

  runIdentityReport(firstName: string, lastName: string, email: string, ovId: string, phoneNumber: string,
                    address1: string, address2: string, city: string, state: string, postalCode: string, country: string, lastIpUsed: string): Observable<IdentityScoreReport> {
    const address = !address1 ? null : {
      address1,
      address2,
      city,
      country,
      postalCode,
      state
    };
    const body = {
      address,
      email,
      firstName,
      lastName,
      ovId,
      phoneNumber: phoneNumber.replace(/\D/g, ''),
      ipAddress: lastIpUsed
    };
    return this.http.post<IdentityScoreReport>(`${this.config.uidUrl}/identityreport`, body)
  }

  getIdentityReport(ovConsumerId: string, pageRequest: PageRequest, filters: FilterField[] = []): Observable<PageResponse<IdentityReport>> {
    let searchParams  = new HttpParams()
      .append('ovConsumerId', ovConsumerId)
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      searchParams = searchParams.append(filter.parameter, filter.value);
    });

    return this.http.get<PageResponse<IdentityReport>>(`${this.config.uidUrl}/api/identity-scores`, {params: searchParams})
      .pipe(map(pageResponse => {
        const reports = pageResponse.content;
        pageResponse.content = reports.map(report => {
          report.identityReportResponse = JSON.parse(report.identityReportResponse);
          return report;
        })
        return pageResponse;
      }));
  }

}
