import {Component, Input, OnInit} from '@angular/core';
import {RewardProgram} from '../../../generated/offers/model/rewardProgram';

@Component({
  selector: 'app-reward-program-card',
  templateUrl: './reward-program-card.component.html',
  styleUrls: ['./reward-program-card.component.css']
})
export class RewardProgramCardComponent implements OnInit {

  @Input()
  loading: boolean

  @Input()
  rewardProgram: RewardProgram

  constructor() { }

  ngOnInit(): void {
  }

}
