import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {FilterField} from '../domain/common/search/filter-field';
import { catchError, map } from 'rxjs/operators';
import {APP_CONFIG, Config} from '../../../config/config';

@Injectable()
export class ReportService extends BaseService {

  constructor(@Inject(APP_CONFIG) readonly config: Config,
               private http: HttpClient) {
    super();
  }

  getReport(id: number, filterParams: FilterField[]) {
    let url = `${this.config.decafUrl}/report?id=${id}`;
    filterParams.forEach(item => {
      url = url + '&' + item.parameter + '=' + item.value
    });
    return this.http.get<any>(url)
      .pipe(catchError(this.handleError));
  }
}
