import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import {PageRequest} from '../../../domain/common/paging';
import {OffersService} from '../../../services/offers-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Merchant} from '../../../generated/offers/model/merchant';

@Component({
  selector: 'app-page-merchants-offers',
  templateUrl: './page-merchants-offers.component.html',
  styleUrls: ['./page-merchants-offers.component.css']
})
export class PageMerchantsOffersComponent implements OnInit {

  merchants: Merchant[] = [];
  loading = true;
  cols: any[];
  formVisible = false;
  selectedItem: Merchant = null;
  selectedOwner: string = null;

  form: FormGroup;
  totalRecords: number;

  @ViewChild('dt') datatable: Table;

  constructor(private offersService: OffersService,
              private messageService: MessageService,
              private router: Router,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initializeForm();
    this.cols = [
      { field: 'status', header: 'Status' },
      { field: 'name', header: 'Name' },
      { field: 'created', header: 'Created' },
      { field: 'updated', header: 'Updated' },
      { field: 'uuid', header: 'UUID' },
      { field: 'merchantIdentifier', header: 'MID' },
      { field: 'verified', header: 'Verified' },
    ];
  }

  initializeForm() {
    this.form = this.fb.group({
      name: [''],
      uuid: [''],
      removed: [false],
      type: ['MID']
    });
  }

  clearForm() {
    this.form.reset()
    this.form.value.type = 'MID';
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadDataLazy(this.datatable.createLazyLoadMetadata());
    this.form.markAsPristine();
  }

  getMerchants(pageRequest: PageRequest) {
    this.loading = true;
    this.offersService.getMerchants(pageRequest, this.form?.value ? this.form.value : null).subscribe(result => {
      this.totalRecords = result.totalElements;
      this.merchants = result.content;
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  loadDataLazy(event: LazyLoadEvent) {
    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};
    this.getMerchants(pageRequest);
  }

  uploadCompleted(merchant) {
    this.selectedItem = null;
    this.datatable.reset();
    this.formVisible = false;
    this.router.navigateByUrl(`offers/merchant/${merchant.uuid}`);
  }


  update(merchant: Merchant) {
    this.selectedItem = merchant;
    this.formVisible = true;
  }

  delete(merchant: Merchant) {
    this.loading = true;
    this.offersService.deleteMerchant(merchant.uuid).subscribe(result => {
      this.loadDataLazy(this.datatable.createLazyLoadMetadata());
    }, err => {
      this.loading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to delete merchant. ${err.message}`});
    });
  }
}
