import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {BuildInfo} from '../domain/common/buildinfo';
import {APP_CONFIG, Config} from '../../../config/config';

@Injectable()
export class CVaultService extends BaseService {

  constructor(@Inject(APP_CONFIG) readonly config: Config,
              private http: HttpClient) {
    super();
  }

  getBuildInfo() {
    return this.http.get<BuildInfo>(`${this.config.cVaultUrl}/build`);
  }

  }
