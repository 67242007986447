/**
 * offers-api
 *
 * Contact: kkozak@ovloop.com, mhernandez@ovoop.com, bpico@ovloop.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateVerifiedLocationStatus { 
    /**
     * UUID representing the unique identifier of the verified merchant location within Offer ecosystem.
     */
    id: string;
    /**
     * Status must be either a INACTIVE, ACTIVE or REMOVED value
     */
    status: UpdateVerifiedLocationStatus.StatusEnum;
}
export namespace UpdateVerifiedLocationStatus {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'REMOVED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Removed: 'REMOVED' as StatusEnum
    };
}


