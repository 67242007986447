import {Inject, Injectable} from '@angular/core';
import {Observable, of, throwError as observableThrowError} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {PageRequest} from '../domain/common/paging';
import {BaseService} from './base.service';
import {KycAttempt} from '../domain/sherlock/kyc/kyc-attempt';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PageResponse} from '../domain/common/paging';
import {FilterField} from '../domain/common/search/filter-field';
import {SherlockUser} from '../domain/sherlock/sherlock-user';
import {SherlockAddress} from '../domain/sherlock/address/sherlockAddress';
import {BuildInfo} from 'app/domain/common/buildinfo';
import {APP_CONFIG, Config} from '../../../config/config';


@Injectable()
export class SherlockService extends BaseService {

  constructor(@Inject(APP_CONFIG) readonly config: Config,
              private http: HttpClient) {
    super();
  }

  getBuildInfo() {
    return this.http.get<BuildInfo>(`${this.config.sherlockUrl}/build`);
  }

  getSherlockUser(uuid: string): Observable<SherlockUser> {
    return this.http.get(`${this.config.sherlockUrl}/admin/user/${uuid}`).
    pipe(
      catchError(this.handleError),
      map(sherlock => {
        if (sherlock.kyc === null) {
          sherlock.kyc = new KycAttempt();
          sherlock.kyc.kycCompleted = false;
        }
        return sherlock;
      })
    )
  }

  // getKycDetail(user: string): Observable<SherlockUser>

  deleteKycAttempt(uuid: string) {
    return this.http.delete(`${this.config.sherlockUrl}/admin/kyc/${uuid}`)
      .pipe(catchError(this.handleError))
  }

  getKycAttempts(pageRequest: PageRequest, filters: FilterField[] = []): Observable<PageResponse<KycAttempt>> {
    let searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      if (filter.value != null) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    return this.http.get<PageResponse<KycAttempt>>(`${this.config.sherlockUrl}/admin/kyc`,
      {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getKycAttemptByUuid(uuid: string): Observable<KycAttempt> {
    return this.http.get<KycAttempt>(`${this.config.sherlockUrl}/admin/kyc/${uuid}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  manualKyc(userUuid: string, firstName: string, lastName: string,
            address1: string, address2: string, city: string, state: string, zipCode: string, country: string,
            social: string, birthDay: number, birthMonth: number, birthYear: number): Observable<SherlockUser> {
    const url = `${this.config.sherlockUrl}/kyc/manual`;
    let body = {
      'userId': userUuid,
      'firstName': firstName,
      'lastName': lastName,
      'address1': address1,
      'address2': address2,
      'city': city,
      'state': state,
      'zipCode': zipCode,
      'country': country,
      'social': social,
      'birthDayOfMonth': birthDay,
      'birthMonth': birthMonth,
      'birthYear': birthYear
    };
    return this.http.post<SherlockUser>(url, body)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getAddresses(pageRequest: PageRequest, filters: FilterField[] = []): Observable<PageResponse<SherlockAddress>> {
    let searchParams  = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      searchParams = searchParams.append(filter.parameter, filter.value);
    });

    return this.http.get<PageResponse<SherlockAddress>>(`${this.config.sherlockUrl}/admin/address`, {params: searchParams})
      .pipe(
        map(page => {
           page.content = page.content.map(address => {
             return Object.assign(new SherlockAddress(), address);
           });
           return page;
        }),
        catchError(error => {
          if (error.name !== 'TimeoutError') {
            return observableThrowError('Server took too long to respond.');
          } else {
            return observableThrowError(error)
          }
        })
      );
  }

  deleteSherlockUser(uuid: string): Observable<any> {
    return this.http.delete(`${this.config.sherlockUrl}/user/${uuid}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getUserPage(pageRequest: PageRequest, filters: FilterField[] = []): Observable<PageResponse<SherlockUser>> {
    let searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      if (filter.parameter === 'phone') {
        searchParams = searchParams.append(filter.parameter, this.removeNonNumeric(filter.value));
      } else if (filter.value != null) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    return this.http.get<PageResponse<SherlockUser>>(`${this.config.sherlockUrl}/admin/user`, {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  private removeNonNumeric(phone: string) {
    return phone.replace(/[^0-9]/g, '');
  }
  // rawPersonSearchResponseToJson(sherlock: KycAttempt) {
  //   if (sherlock && !(sherlock.personSearchResponseJson)) {
  //     let xml2js = require('xml2js');
  //     let parser = new xml2js.Parser({explicitArray: false});
  //
  //     let xml = sherlock.rawPersonSearchResponse;
  //     parser.parseString(xml, function (err, result) {
  //       sherlock.personSearchResponseJson = result;
  //     });
  //   }
  // }

  getUserAddress(uuid: string, source = "USER_KYC" ) {
    let searchParams  = new HttpParams()
      .append('page', '0')
      .append('size', '25')
      .append('user', uuid)
      .append('source', source);

    return this.http.get<PageResponse<SherlockAddress>>(`${this.config.sherlockUrl}/admin/address`, {params: searchParams})
      .pipe(
        map(page => {
          page.content = page.content.map(address => {
            return Object.assign(new SherlockAddress(), address);
          });
          return page;
        }),
        catchError(error => {
          if (error.name !== 'TimeoutError') {
            return observableThrowError('Server took too long to respond.');
          } else {
            return observableThrowError(error)
          }
        })
      );
  }
}
