import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {APP_CONFIG, Config} from '../../../../config/config';

@Component({
  selector: 'app-merchant',
  templateUrl: './merchant.component.html',
  styleUrls: ['./merchant.component.css']
})
export class MerchantComponent implements OnInit {

  merchantPortalLoadComplete = false;
  loadCounter = 0;
  merchantPortalUrl: SafeResourceUrl;
  @ViewChild('merchantPortaliFrame') iframe: ElementRef;

  constructor(@Inject(APP_CONFIG) readonly config: Config,
              public sanitizer: DomSanitizer) {
    this.merchantPortalUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getMerchantPortalUrl());
  }

  ngOnInit() {
    setTimeout(item => {
      this.merchantPortalLoadComplete = true;
    }, 10000);
  }

  getMerchantPortalUrl() {
    return this.config.merchantUIOrigin + '/#/login';
  }

  merchantPortalLoaded(event) {
    this.loadCounter = this.loadCounter + 1;
    console.log('Hidden merchant portal iFrame load complete.');
    if (this.loadCounter > 1) {
      this.merchantPortalLoadComplete = true;
    }
  }

  sendToken() {
    const iframeWindow = this.iframe.nativeElement.contentWindow;
    // iframeWindow.frames.postMessage({'ganejwt': BaseService.ganeJwt}, this.config..merchantUIOrigin);
    setTimeout(it => {
      console.log('Launching in new tab');
      window.open(this.config.merchantUIOrigin + '/#/home', '_blank');
    }, 200);
  }


}
