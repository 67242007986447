import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Country} from '../../../domain/forms/country';
import {Region} from '../../../domain/forms/region';
import {LocalDataService} from '../../../services/local-data.service';
import {OffersService} from '../../../services/offers-api.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {Organization} from '../../../domain/enterprise/organization';
import {EnterpriseApiService} from '../../../services/enterprise-api.service';
import {Merchant} from '../../../generated/offers/model/merchant';

@Component({
  selector: 'app-form-brand',
  templateUrl: './form-brand.component.html',
  styleUrls: ['./form-brand.component.css']
})
export class FormBrandComponent implements OnInit {

  form: FormGroup;
  countries: Country[] = [];
  states: Region[] = [];

  brandOptions: Organization[] = [];
  organizationOptions: Organization[] = [];

  isLoading = false;

  @Output() created = new EventEmitter<Merchant>();

  constructor(private fb: FormBuilder,
              private localDataService: LocalDataService,
              private router: Router,
              private offersService: OffersService,
              private enterpriseApiService: EnterpriseApiService,
              private messageService: MessageService) { }

  ngOnInit() {
    this.initializeForm();
  }

  filterNonAlpha(newValue) {
    return newValue.key.match(/[a-zA-Z\s]+/) !== null;
  }

  initializeForm() {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
      owner: ['', [Validators.maxLength(255)]],
      entOrg: [null, [Validators.required]],
      entOrgId: ['', [Validators.maxLength(255)]],
      entBrand: [null, [Validators.required]],
      entBrandId: ['', [Validators.maxLength(255)]],
      verified: [true],
      // 'premier': [{value: this.merchant?.premier}],
      giftOption: [false],
      // 'identifier': [{value: '', disabled: false}, [Validators.maxLength(19)]],
      // 'city': [{value: '', disabled: false}, [Validators.maxLength(16)]],
      // 'state': [{value: 'All', disabled: false}],
      // 'country': [{value: 'All', disabled: false}],
      // 'shortUrl': [this.merchant.shortUrl],
      // 'vanityUrl': [null],
    });
  }

  searchBrands(event) {
    this.enterpriseApiService.getOrganizations({name: event.query, isParent: false}).subscribe(results => {
      this.brandOptions = results.filter(org => org.isParent === false);
    });
  }

  searchOrgs(event) {
    this.enterpriseApiService.getOrganizations( {name: event.query, isParent: true}).subscribe(results => {
      this.organizationOptions = results;
    });
  }

  save() {
    this.isLoading = true;

    let brandId = this.form.value.entBrand;
    if (!(typeof brandId === 'string' || brandId instanceof String)) {
      brandId = brandId.id;
    }
    this.form.patchValue({entBrandId: brandId});

    let orgId = this.form.value.entOrg;
    if (!(typeof orgId === 'string' || orgId instanceof String)) {
      orgId = orgId.id;
    }
    this.form.patchValue({entOrgId: orgId});

    const request = Object.assign({}, this.form.value);
    request.entBrand = null;
    request.entOrg = null;
    delete request.entBrand;
    delete request.entOrg;

    return this.offersService.createBrand(request).subscribe(result => {
      this.isLoading = false;
      this.router.navigateByUrl(`offers/merchant/${result.uuid}`);
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to save brand. ${error.message}`});
      this.isLoading = false;
    });
  }

}
