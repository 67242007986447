import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PageRequest} from '../../../domain/common/paging';
import {FilterField} from '../../../domain/common/search/filter-field';
import {LazyLoadEvent} from 'primeng/api';
import {OffersService} from '../../../services/offers-api.service';
import {ErrorDisplayService} from '../../../services/error-display.service';
import {User} from '../../../generated/offers/model/user';

@Component({
  moduleId: '{{module.id}}',
  selector: 'app-page-search-offers-users',
  templateUrl: './page-search-offers-users.component.html',
  styleUrls: ['./page-search-offers-users.component.css']
})
export class PageSearchOffersUsersComponent implements OnInit {

  users: User[];

  @ViewChild('dt') datatable: Table;
  cols: any[];
  totalRecords: number;
  loading = false;
  form: FormGroup;

  constructor(private offersService: OffersService,
              private errorDisplayService: ErrorDisplayService,
              private fb: FormBuilder) {}

  ngOnInit() {
    this.initializeForm();

    this.cols = [
      {field: 'created', header: 'Created', sort: true},
      {field: 'uuid', header: 'UUID', sort: true},
      {field: 'firstName', header: 'First', sort: true},
      {field: 'lastName', header: 'Last', sort: true},
      {field: 'hold', header: 'Held', sort: true},
      // {field: 'kyc', header: 'KYC', sort: true}
    ];
  }

  initializeForm() {
    this.form = this.fb.group({
      hold: [null],
    });
  }

  clearForm() {
    this.form.reset();
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadUsersLazy(this.datatable.createLazyLoadMetadata());
    this.form.markAsPristine();
  }

  formTrigger() {
    if (this.form.dirty) {
      this.doSearch();
    }
  }

  getUsers(pageRequest: PageRequest, filters: FilterField[]): void {
    this.loading = true;
    this.offersService.getOffersUserPage(pageRequest, filters).subscribe(page => {
      this.users = page.content;
      this.totalRecords = page.totalElements;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Users', error);
    }).add(() => {
      this.loading = false;
    });
  }


  loadUsersLazy(event: LazyLoadEvent) {
    const filters: FilterField[] = [{
      parameter: 'sort',
      value: event.sortField + ',' + (event.sortOrder === 1 ? 'asc' : 'desc')
    }];

    if (this.form.controls.hold.value != null) {
      filters.push({parameter: 'hold', value: this.form.controls.hold.value});
    }

    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};
    this.getUsers(pageRequest, filters);
}
}
