// Build information, automatically generated by `ng-info`
const build = {
    version: "2.29.0",
    timestamp: "Mon Mar 17 2025 15:41:42 GMT-0400 (Eastern Daylight Saving Time)",
    message: null,
    git: {
        user: "Patrick Ziajski",
        branch: "develop",
        hash: "6cb0fb",
        fullHash: "6cb0fbf45e951e9d22697a5df355e561dd2f38d8"
    }
};

export default build;